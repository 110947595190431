<template >
  <div class="furniture-detail newfurniture-detail">
    <ProductImages :images="product.cloudinaryImages" ref="productImages" />

    <div class="info">
      <p v-if="isPreview" class="subtitle">
        <span>{{ product?.vendor?.name }}</span>
      </p>
      <p v-else>
        <a :href="product.link" target="_blank">{{product?.vendor?.name}}</a>
      </p>
      <p class="title">{{product?.name}}</p>
    </div>

    <div class="details">
      <table class="details__grid">
        <tr v-if="product?.materialsFinish">
          <td>Materials</td>
          <td>{{ product?.materialsFinish }}</td>
        </tr>
        <tr v-if="product?.size">
          <td>Dimensions</td>
          <td>{{ product?.size }}</td>
        </tr>
        <tr>
          <td>Price</td>
          <td>${{ product?.itemPrice }}</td>
        </tr>
      </table>

      <ul class="details__description">
        <li v-for="(lineItem, index) in descriptionFormatted" :key="`line-item-${index}`">
          {{ lineItem }}
        </li>
      </ul>
    </div>

    <button
      v-if="isSelectedItem"
      type="button"
      class="primary customGreenBTN"
      @click="handleRemoveSelection"
    >
      REMOVE SELECTION
    </button>

    <button
      v-else
      type="button"
      class="primary customGreenBTN"
      @click="handleAddSelection"
    >
      {{ isPreview ? 'DEMO' : 'YES, I WANT IT' }}
    </button>
    <img
      class="close-button"
      src="@/assets/images/close-icon.png"
      alt="close-button"
      @click="handleClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductImages from '@/components/results/ProductImages.vue'

export default {
  name: 'furniture-detail',

  components: {
    ProductImages
  },

  props: {
    product: {
      type: Object,
      default: null
    },

    targetFurnitureId: {
      type: String,
      default: ''
    },

    isPreview: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      index: 0,
      isSelectedItem: false
    }
  },

  computed: {
    ...mapState(['selectedItems']),
    descriptionFormatted () {
      if (!this.product.clientDescription) return []
      const lineItems = this.product.clientDescription.split('- ') // break into separate items
      return lineItems.filter(n => n.length > 1) // filter empty items & spaces
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleWindowResize)
    this.index = 0

    this.selectedItems.forEach((item) => {
      if (this.product.recordId === item.id) {
        this.isSelectedItem = true
      }
    })
  },

  methods: {
    handleClose (e) {
      this.$emit('FURNITURE_DETAIL_CLOSE')
    },

    handleRemoveSelection (e) {
      this.$emit('FURNITURE_DETAIL_ITEM_REMOVE', {
        id: this.product.recordId
      })
    },

    handleAddSelection () {
      this.$emit('AUTO_ADD_ITEM', {
        id: this.product.recordId
      })

      this.handleClose()
    }
  },

  watch: {
    // product: {
    //   handler: function () {
    //     this.$refs.productImages.slideshow()
    //   },
    //   deep: true
    // }
  }
}
</script>

<style lang="scss" scoped>
.furniture-detail {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  overflow: hidden;
  overflow-y: scroll;
   * {
     font-weight: 300 !important;
   }

  .info {
    margin: var(--gutter) 0px;
    text-align: center;

    .subtitle {
      font-family: 'GT America Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 133%;
      letter-spacing: 0.12em;
      margin-bottom: 5px;
      text-transform: uppercase;
      color: #576B5C;
      text-decoration: underline;
    }

    .title {
      font-family: 'GT America Expanded';
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.04em;
      color: #1D1B19;
    }
  }

  .details {
    font-family: 'GT Pressura Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    //text-transform: uppercase;
    color: #73716F;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 10px;
    margin-bottom: var(--gutter);
    text-align: left;
    width: 80%;
    height: calc(200px - var(--gutter));
    overflow: hidden;
    overflow-y: scroll;

    &__grid {
      margin-bottom: var(--gutter);
      table, tr {
        width: 100%;
      }
      td {
        width: 50%;
      }
    }
    &__description {
      display: list-item;
      list-style: disc;
      margin-left: var(--gutter);
      li {
        list-style: disc !important;
      }
    }
  }

  button.primary {
    padding: 12px 40px;
    order: 1;
    min-width: 100px;
  }

  .close-button {
    position: absolute;
    float: right;
    background: white;
    top: 25px;
    right: 20px;
    width: 40px;
    height: 40px;
    padding: 2px;
    cursor: pointer;
  }
}

</style>
